import axiosInstance from "../axiosInstance";
import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";

export const newMember = createAsyncThunk(
  "newMember",
  async ({ token, id }, { dispatch }) => {
    console.log("Creating new member...");
    try {
      const response = await axiosInstance.get(`/get_restaurant_owners/${id}`, {
        headers: {
          Authorization: token,
        },
      });
      dispatch(setNewMember(response.data));
      return response.data;
    } catch (err) {
      console.log("Error occurred:", err.message);
      throw err;
    }
  }
);

const initialState = {
  newMember: [],
};

const newMemberSlice = createSlice({
  name: "newMember",
  initialState,
  reducers: {
    setNewMember(state, action) {
      state.newMember = action.payload;
    },
  },
});

export const { setNewMember } = newMemberSlice.actions;

const new_Member = (state) => state.newMemberSlice.newMember;

export const selectNewMember = createDraftSafeSelector(
  new_Member,
  (newMember) => {
    return newMember;
  }
);

export default newMemberSlice.reducer;
